import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from 'components/global/layout';
import Seo from 'components/global/seo';

import 'styles/pages/blog/post.scss';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;

  const backToTopHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  let featuredImg = getImage(
    post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
  );
  return (
    <Layout>
      <Seo title={post.frontmatter.title} />
      <div className='blog'>
        <div className='blog-heading'>
          <div className='blog-heading-content'>
            <Link to='/blog' className='back'>
              <i className='uil uil-angle-left-b'></i>
              All Articles
            </Link>
            <h1 className='title'>{post.frontmatter.title}</h1>
            <div className='author'>
              <i className='uil uil-pen'></i>
              <p className='author-text'>{post.frontmatter.author}</p>
            </div>
            {post.frontmatter.tags.split(' ').map((tag, index) => {
              return (
                <span key={index} className='blog-tag'>
                  {tag}
                </span>
              );
            })}
            <p className='date'>{post.frontmatter.featuredDate}</p>
          </div>
        </div>
        <div className='blog-content'>
          <GatsbyImage image={featuredImg} className='blog-image' />
          <div
            className='blog-html'
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        <div className='blog-footer'>
          <Link to='/blog' className='back action'>
            <i className='uil uil-arrow-left'></i>
            All Articles
          </Link>
          <button className='action' onClick={backToTopHandler}>
            <i className='uil uil-top-arrow-from-top'></i>
            Back To Top
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        author
        featuredDate
        featuredImage {
          childImageSharp {
            gatsbyImageData(height: 400)
          }
        }
      }
    }
  }
`;
